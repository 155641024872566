import React from 'react'
import defaultSettings from './defaultSettings';
import {gatewayConfig} from './gateway';
import Icon from '@ant-design/icons';
import { CodeSlash, Person, Upload, Building, Diagram3, GraphUp, ClipboardCheck, ReceiptCutoff, Broadcast, Coin, Database } from 'react-bootstrap-icons';
import { AppstoreAddOutlined, FileAddFilled, FormatPainterFilled, WalletFilled, DashboardFilled, DatabaseFilled, TagFilled, CalendarFilled, FundFilled, FileDoneOutlined } from '@ant-design/icons';

const { REACT_APP_ENV } = process.env;

const prebidIcon = () => <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_573_98)">
<path d="M17.6279 1.05855L16.1057 2.12219L15.3836 2.61986L14.5736 3.18583C14.4273 3.29317 14.3882 3.49809 14.4956 3.64446L14.72 3.96648L7.61606 8.94315L7.39163 8.62113C7.28429 8.47476 7.07936 8.43572 6.93299 8.54306L5.41072 9.6067L4.68861 10.1044L3.87869 10.6703C3.73231 10.7777 3.69328 10.9826 3.80062 11.129L8.44551 17.7645C8.55285 17.9109 8.75777 17.9499 8.90414 17.8426L11.9584 15.7056C12.1048 15.5982 12.1439 15.3933 12.0365 15.2469L11.8121 14.9249L13.793 13.5392L19.726 22.0093C19.726 22.0093 19.7552 22.0484 19.7747 22.0581C19.7845 22.0972 19.804 22.1264 19.8235 22.1557C20.4285 23.0242 21.6288 23.2389 22.4973 22.6241C23.3657 22.0093 23.5804 20.8188 22.9657 19.9504C22.9461 19.9211 22.9169 19.8918 22.8876 19.8625C22.8876 19.843 22.8681 19.8137 22.8583 19.7942L16.9254 11.3241L18.9063 9.93848L19.1307 10.2605C19.238 10.4069 19.443 10.4459 19.5893 10.3386L22.6436 8.20153C22.79 8.09419 22.829 7.88927 22.7217 7.74289L18.0866 1.13661C17.9792 0.990242 17.7743 0.951209 17.6279 1.05855Z" fill="currentColor"/>
<path d="M11.1778 20.7505H11.1485V19.9894C11.1485 19.1209 10.4459 18.4281 9.58719 18.4281H3.7518C2.88333 18.4281 2.1905 19.1307 2.1905 19.9894V20.7505H2.16122C1.51718 20.7505 1 21.2677 1 21.9118V23.9902H12.3487V21.9118C12.3487 21.2677 11.8316 20.7505 11.1875 20.7505H11.1778Z" fill="currentColor"/>
</g>
<defs>
<clipPath id="clip0_573_98">
<rect width="22.3267" height="23" fill="white" transform="translate(1 1)"/>
</clipPath>
</defs>
</svg>

const {
  INSIGHT_SERVICE,
  INVOICE_SERVICE,
  TAGS_SERVICE,
  SITES_SERVICE,
  FORMATS_SERVICE,
  CAMPAIGN_SERVICE,
  COMPANY_SERVICE,
  PUBLISHERS_SERVICE,
  PLATFORM_SERVICE,
  METRICS_SERVICE,
  USER_SERVICE,
  QUOTA_SERVICE,
  ADMANAGER_SERVICE,
  NOTIFICATION_SERVICE,
  CONTEXTUAL_SERVICE,
} = gatewayConfig;

if(REACT_APP_ENV){
  console.log('REACT_APP_ENV', REACT_APP_ENV)
}

const routes = [
  {
    path: '/',
    component: './pages/Overview/Overview.react',
    roles: ['ROLE_ADMIN']
  },
  {
    name: 'Dashboard',
    path: '/overview',
    component: './pages/Overview/Overview.react',
    icon: <DashboardFilled className="mx-2"/>,
    routes: [ 
      {name: 'Analytics', path: '/overview', roles: ['ROLE_PUBLISHER','ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
      {name: 'Contextual', path: '/contextual', roles: ['ROLE_PUBLISHER','ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
    ],
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL'],
  },
  {
    // name: 'Dashboard',
    path: '/overview',
    component: './pages/Overview/Overview.react',
    icon: <DashboardFilled className="mx-2"/>,
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL'],
  },
  {
    icon:<FileDoneOutlined className='mx-2'/>,
    path: '/contextual',
    component: './pages/Context/Overview.Page',
  },
  {
    name:'Header Bidding',
    icon: <Icon style={{fill:'red'}} className="mx-2" component={prebidIcon}></Icon>,
    path: '/prebid',
    component: './pages/Prebid/Prebid.Overview',
    roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']
  },
  {
    path: '/live',
    icon: <Broadcast className="mx-2"/>,
    component: './pages/Dashboard/Dashboard.react',
    roles: ['ROLE_ADMIN', 'ROLE_OP']
  },
  {
    path: '/overview/:id',
    component: './pages/Overview/Overview.Site',
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/contextual/:id',
    component: './pages/Context/Overview.Site',
    roles: ['ROLE_PUBLISHER', 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/prebid/:id',
    component: './pages/Prebid/Prebid.Detail',
    roles: [ 'ROLE_FINANCE', 'ROLE_OP', 'ROLE_TL']
  },
  {
    name: 'Data Import',
    path: '/insight',
    icon: <FileAddFilled className="mx-2" />,
    component: './pages/Insight/Insight.Import.react',
    routes: [ 
      { name: 'Daily', path: '/insight/import', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Corrections', path: '/insight/corrections', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Costs', path: '/insight/costs', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
      { name: 'Page Views', path: '/insight/pageviews', roles: ['ROLE_ADMIN', 'ROLE_FINANCE'] },
    ],
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/import',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Import.react',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/costs',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Cost.Import.react',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/costs/list',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Cost.List',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/corrections',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Insight.Correction',
    roles: ['ROLE_FINANCE']
  },
  {
    path: '/insight/pageviews',
    icon: <Upload className="mx-2" />,
    component: './pages/Insight/Pageviews.Import',
    roles: ['ROLE_FINANCE']
  },
  {
    name: 'Invoices',
    path: '/invoiceManager',
    icon: <WalletFilled className="mx-2" />,
    component: './pages/Invoices/Invoice.react',
    roles: ['ROLE_FINANCE', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    name: 'Tag Manager',
    path: '/tagmanager',
    icon: <TagFilled className="mx-2" />,
    component: './pages/TagManager/TagManager.List.react',
    roles: ['ROLE_OP']
  },
  {
    path: '/tagmanager/new',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.Configurator.react',
    roles: ['ROLE_OP']
  },  
  {
    path: '/tagmanager/formats',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.FormatConfigurator',
    roles: []
  },
  {
    path: '/tagmanager/:id',
    icon: <CodeSlash className="mx-2" />,
    component: './pages/TagManager/TagManager.Configurator.react',
    roles: ['ROLE_OP' ]
  },
  {
    path: '/invoiceManager',
    icon: <ReceiptCutoff className="mx-2" />,
    component: './pages/Invoices/InvoiceNew.react',
    roles: ['ROLE_FINANCE', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  // {
  //   name: 'Campaigns',
  //   path: '/campaigns',
  //   icon: <CalendarFilled className="mx-2" />,
  //   component: './pages/Campaigns/Campaigns.react',
  //   roles: ['ROLE_FINANCE, ROLE_OP']
  // },
  // {
  //   path: '/campaigns/:id',
  //   icon: <CalendarFilled className="mx-2" />,
  //   component: './pages/Campaigns/Campaigns.Campaign',
  //   roles: ['ROLE_FINANCE, ROLE_OP']
  // },
  {
    name: 'Inventory',
    path: '/inventory',
    icon: <DatabaseFilled className="mx-2" />,
    component: './pages/Platforms/Platforms.Manager',
    routes: [ 
      { name: 'Platforms', path: '/platformManager', roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP']},
      { name: 'Publishers', path: '/companies', roles: ['ROLE_ADMIN','ROLE_FINANCE', 'ROLE_OP'] },
      { name: 'Sites', path: '/sites', roles: ['ROLE_ADMIN','ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL'] },
      { name: 'Users', path: '/users', roles: ['ROLE_ADMIN', 'ROLE_OP'] },
    ],
    roles: ['ROLE_OP']
  },
  {
    path: '/platformManager',
    icon: <AppstoreAddOutlined />,
    component: './pages/Platforms/Platforms.Manager',
    roles: ['ROLE_FINANCE', 'ROLE_OP']
  },
  {
    path: '/companies',
    icon: <Building className="mx-2" />,
    component: './pages/Companies/CompaniesManager',
    roles: ['ROLE_FINANCE', 'ROLE_OP']
  },  
  {
    path: '/pending-approval',
    component: './pages/Signup/PendingApproval',
    roles: ['ROLE_GUEST']
  },
  {
    path: '/sites/sitemanager',
    component: './pages/Sites/Sites.SiteManager',
    roles: ['ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL']
  },
  {
    path: '/users',
    icon: <Person className="mx-2" />,
    component: './pages/Users/UsersManager.react',
    roles: ['ROLE_ADMIN',  'ROLE_OP']
  },  
  {
    path: '/sites',
    icon: <Diagram3 className="mx-2"/>,
    component: './pages/Sites/Sites.react',
    roles: ['ROLE_PUBLISHER', 'ROLE_OP', 'ROLE_TL'],
    // routes: [
    //   { name: 'All Sites', path: '/sites', disabled: false },
    //   { name: 'Pending Sites', path: '/sites/sitemanager', icon: < ClipboardCheck className='mx-2'/> },
    // ]
  },
  {
    name: 'White Label',
    path:'/company',
    icon: <FormatPainterFilled className="mx-2" />,
    component: './pages/Companies/CompanyManager.react',
    routes: [ 
      { name: 'Company', path: '/company', roles: ['ROLE_ADMIN', 'ROLE_TL']  }
    ],
    roles: ['ROLE_ADMIN', 'ROLE_TL']
  },
  {
    path: '/company',
    icon: <Building className="mx-2" />,
    component: './pages/Companies/CompanyManager.react',
    roles: ['ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    path: '/profile',
    icon: <Person className="mx-2" />,
    component: './pages/Profile/ProfileManager.react',
    roles: ['ROLE_ADMIN', 'ROLE_OP', 'ROLE_PUBLISHER', 'ROLE_TL']
  },
  {
    path: '/businessReview',
    name: 'Business Review',
    icon: <FundFilled className="mx-2" />,
    component: './pages/BusinessReview/BusinessReview',
    roles: ['ROLE_ADMIN', 'ROLE_OP']
  },
  {
    path: '/notifications',
    component: './components/Notification/Notification.Page',
    roles: []
  },
]

export const defineConfig = {
  ...defaultSettings,
  theme: "light",
  // color: "#f44242",
  sidebar: true,
  footer: false,
  routes,
  INSIGHT_SERVICE: INSIGHT_SERVICE(REACT_APP_ENV || 'prod'),
  INVOICE_SERVICE: INVOICE_SERVICE(REACT_APP_ENV || 'prod'),
  FORMATS_SERVICE: FORMATS_SERVICE(REACT_APP_ENV || 'prod'),
  TAGS_SERVICE: TAGS_SERVICE(REACT_APP_ENV || 'prod'),
  SITES_SERVICE: SITES_SERVICE(REACT_APP_ENV || 'prod'),
  PUBLISHERS_SERVICE: PUBLISHERS_SERVICE(REACT_APP_ENV || 'prod'),
  PLATFORM_SERVICE: PLATFORM_SERVICE(REACT_APP_ENV || 'prod'),
  CAMPAIGN_SERVICE: CAMPAIGN_SERVICE(REACT_APP_ENV || 'prod'),
  METRICS_SERVICE: METRICS_SERVICE(REACT_APP_ENV || 'prod'),
  COMPANY_SERVICE: COMPANY_SERVICE(REACT_APP_ENV || 'prod'),
  USER_SERVICE: USER_SERVICE(REACT_APP_ENV || 'prod'),
  QUOTA_SERVICE: QUOTA_SERVICE(REACT_APP_ENV || 'prod'),
  ADMANAGER_SERVICE: ADMANAGER_SERVICE(REACT_APP_ENV || 'prod'),
  NOTIFICATION_SERVICE: NOTIFICATION_SERVICE(REACT_APP_ENV || 'prod'),
  CONTEXTUAL_SERVICE: CONTEXTUAL_SERVICE(REACT_APP_ENV || 'prod')
}


